import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "./media-image.scss";

const MediaImage = (props) => {

  const { attr, image, alt } = props;

  return(
    <div className={`media media--image media--align-${attr['data-align'] || 'none'}`}>
      <GatsbyImage
        alt={alt}
        image={{
          ...image
        }} />
      {attr['data-caption'] &&
        <div className="caption">{attr['data-caption']}</div>
      }
    </div>
  )
}

export default MediaImage;

