import React from "react"
import MediaImage from "../media/media-image";
import MediaVideo from "../media/media-video";
import MediaRemoteVideo from "../media/media-remote-video";
import PropTypes from 'prop-types';

const Media = ({media, attr}) => {

  // The drupal tag looks something like this:
  // <drupal-media alt="sadasd" data-align="left" data-caption="my caption" data-entity-type="media" data-entity-uuid="94632cca-b2d3-44c5-b8c7-f0a5faa8bae1" data-view-mode=""></drupal-media>
  // We could even leverage the view mode to show different display modes.

  if (media.entityBundle === 'image') {
    return (
      <MediaImage key={media.uuid} attr={attr} alt={media.fieldMediaImage.alt} image={media.fieldMediaImage.mediaGatsbyFile.childImageSharp.gatsbyImageData}/>
  )
  }
  if (media.entityBundle === 'video') {
    return (
      <MediaVideo key={media.uuid} attr={attr} src={media.fieldMediaVideoFile.mediaGatsbyFile.publicURL}/>
    )
  }
  if (media.entityBundle === 'remote_video') {
    return (
      <MediaRemoteVideo key={media.uuid} attr={attr} src={media.fieldMediaOembedVideo}/>
    )
  }
  else {
    return null;
  }
}

export default Media;

Media.propTypes = {
  media: PropTypes.object.isRequired,
  attr: PropTypes.object.isRequired,
};
