import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Media from "../media/media";
import parse from 'html-react-parser';
import "./body.scss"

const Body = (props) => {

  const { html } = props;

  const mediaImages = useStaticQuery(graphql`
    query MyQuery {
      drupal {
        mediaQuery (limit: 999999) {
          entities {
            entityId
            entityBundle
            entityUuid
            ... on Drupal_MediaImage {
              mid
              uuid
              fieldMediaImage {
                alt
                mediaGatsbyFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(width: 800)
                  }
                }
                entity {
                  url
                }
              }
            }
            ... on Drupal_MediaVideo {
              mid
              uuid
              fieldMediaVideoFile {
                mediaGatsbyFile {
                  publicURL
                }
                entity {
                  url
                }
              }
            }
            ... on Drupal_MediaRemoteVideo {
              mid
              uuid
              fieldMediaOembedVideo
            }
            ... on Drupal_MediaDocument {
              mid
              uuid
            }
          }
        }
      }
    }
  `);

  const processBody = function(html) {
    if (typeof html !== 'string') {
      return html;
    }
    return parse(html, {
      replace: domNode => {
        if (domNode.type === 'tag' && domNode.attribs['data-entity-type'] === 'media') {
          let uuid = domNode.attribs['data-entity-uuid']
          for (let media of mediaImages.drupal.mediaQuery.entities) {
            if (media.uuid === uuid) {
              return <Media key={uuid} attr={domNode.attribs} media={media}/>
            }
          }
        }
        else {
          return undefined
        }
      }
    });
  }

  return (
    <div className={props.className}>{processBody(html)}</div>
  )
};

export default Body;
