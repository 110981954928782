import React from "react"
import "./media-video.scss";

const MediaVideo = (props) => {

  const { attr, src } = props;

  return(
    <div className={`media media--video media--align-${attr['data-align'] || 'none'}`}>
      <video width="100%" controls>
        <source src={src} type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
      {attr['data-caption'] &&
        <div className="caption">{attr['data-caption']}</div>
      }
    </div>
  )
}

export default MediaVideo;

