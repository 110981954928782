import React from "react";
import { ReactYouTubeLite } from 'react-youtube-lite';
import "./media-remote-video.scss";

const MediaRemoteVideo = (props) => {

  if (!props.src) {
    return null;
  }

  const { attr, src } = props;

  return(
    <div className={`media media--remote-video media--align-${attr['data-align'] || 'none'}`}>
      <ReactYouTubeLite url={src} />
    </div>
  )
}

export default MediaRemoteVideo;

